<template>
    <AppPaginatedGrid
        ref="grid"
        :service="service"
        permission="estrutura_boletins"
        newPath="/boletins/new"
        editPath="/boletins/"
        :expander="true"
        subtitle="Boletim de atualização"
        tooltip="Atualização da aplicação"
        nomeTelaDoManual="boletins-list"
        origemHistoricoAcao="/boletins"
    >
        <template #columns>
            <Column field="descricao" header="Descrição" :sortable="true" sortField="descricao"></Column>
            <Column field="tipo" header="Tipo" :sortable="true" sortField="tipo"></Column>
            <Column field="versao" header="Versão" :sortable="true" sortField="versao"></Column>
            <Column field="createdAt" header="Criado" :sortable="true" sortField="created_at">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.data.createdAt) }}
                </template>
            </Column>
        </template>
        <template v-slot:expansion="slotProps">
            <strong class="block my-2">Descrição Detalhada</strong>
            <div v-html="slotProps.data.descricaoDetalhada" class="m-4 expander"></div>
        </template>
    </AppPaginatedGrid>
</template>

<script>
import BaseService from '../../services/BaseService';

export default {
    data() {
        return {
            tenantCustomers: [],
            service: null
        };
    },
    created() {
        this.service = new BaseService('/boletins');
    },
    methods: {
        async load() {
            this.$refs.grid.load();
        }
    }
};
</script>
